@mixin tn {
  @media(max-width: 383px) {
    @content;
  }
}

@mixin xxs {
  @media(max-width: 479px) {
    @content;
  }
}

@mixin xs {
  @media(max-width: 575px) {
    @content;
  }
}

@mixin sm {
  @media(max-width: 767px) {
    @content;
  }
}

@mixin md {
  @media(max-width: 991px) {
    @content;
  }
}

@mixin lg {
  @media(max-width: 1199px) {
    @content;
  }
}

@mixin xl {
  @media(max-width: 1399px) {
    @content;
  }
}

@mixin xxl {
  @media(max-width: 1919px) {
    @content;
  }
}

@mixin xxxl {
  @media(max-width: 2999px) {
    @content;
  }
}

@mixin max {
  @media(max-width: 99999px) {
    @content;
  }
}

@mixin h-center-content {
  display: flex;
  justify-content: center;
}

@mixin v-center-content {
  display: flex;
  align-items: center;
}

@mixin center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-item($grow: 0, $shrink: 1, $basis: auto) {
  display: flex;
  position: relative;
  flex: $grow $shrink $basis;
}

@mixin font-face($font, $color, $letterSpacing: 0px) {
  font: $font;
  color: $color;
  letter-spacing: $letterSpacing;
}

@mixin lab-card-gradients {
  &.gradient-1 {
    background: linear-gradient(180deg, #757f9a 0%, #d7dde8 100%);
  }
  &.gradient-2 {
    background: linear-gradient(180deg, #245a9e 0%, #5ea6fc 100%);
  }
  &.gradient-3 {
    background: linear-gradient(205.19deg, #d3d5d9 7.88%, #a4adc0 100%);
  }
  &.gradient-4 {
    background: linear-gradient(156deg, #5DA5FA 2.21%, #255CA0 101.02%);
  }
  &.gradient-5 {
    background: linear-gradient(13deg, #757F9A 10.79%, #D7DDE8 108.4%);
  }
}
@mixin lab-card-gradients-to-single-color {
  &.gradient-1, &.gradient-3, &.gradient-4 {
    background-color: #757f9a;
  }
  &.gradient-2, &.gradient-5 {
    background-color: #205eab;
  }
}