@import "styles/utils";
@import "styles/font_variables.scss";

.auth-form-sso {
  &-btns, &-seperator {
    margin-left: 24px;
    margin-right: 24px;
    
  }
  &-seperator {

    margin-top: 24px;
    margin-bottom: 24px;
  }

  &-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;

    .MuiButtonBase-root {
      @include font-face($font-poppins-600-14px, #202020);
      height: 48px;
      border-radius: 8px;
      width: 100%;
      max-width: 173px;
      border: 1px solid #dcdcdc;
      background: #fff;
      text-transform: none;
      img {
        width: 16px;
        height: 16px;
      }
      &:hover {
        background: #fff;
        box-shadow: none;
      }
    }
  }

}
