@import "styles/utils";
@import "styles/font_variables";

.auth-form-verify {
  max-width: 370px;
  margin: 0 auto;
  &-title {
    @include font-face($font-poppins-600-24px, #202020);
  }
  &-subtitle {
    @include font-face($font-poppins-400-14px-1_7, #5a5a5a);
  }
}
