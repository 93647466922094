@import "styles/utils";
@import "styles/font_variables.scss";

.auth-modal {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1254px;
  width: 100%;
  max-height: min(821px, calc(100% - 100px));
  height: 100%;

  background-color: #fff;
  box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
  border-radius: 20px;
  z-index: 100;

  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  white-space: pre-line;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
  }

  .auth-header {
    @include flex-column;
    justify-content: center;
    align-items: center;
    max-width: 606px;
    width: 100%;
    height: 100%;
    box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);

    img {
      height: 245px;
      width: auto;
    }

    &-title {
      @include font-face($font-volkhov-700-36px-1_27, #202020);
      margin-bottom: 10px;
      margin-top: 50px;
      margin-right: 60px;
    }

    &-subtitle {
      @include font-face($font-poppins-400-14px-1_7, #5a5a5a);
      margin-bottom: 32px;
    }

    .auth-header-content {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &-details {
        display: flex;
        flex-direction: row;
        gap: 9px;

        &-icon {
          width: 24px;
          height: 24px;
        }

        &-container {
          display: flex;
          flex-direction: column;
          margin-top: 2px;

          &-title {
            @include font-face($font-poppins-600-14px, #202020);
          }

          &-description {
            @include font-face($font-poppins-400-14px, #5a5a5a);
          }
        }
      }
    }
  }

  .auth-form-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .auth-form {
      display: flex;
      flex-direction: column;
      margin: 20px auto;
      max-width: 418px;
      width: 100%;
      height: fit-content;

      &-terms-and-conditions,
      &-footer{
        margin-left: 24px;
        margin-right: 24px;
      }

      &-terms-and-conditions {
        margin-top: 5px;
        margin-right: 0;
        .MuiTypography-root {
          @include font-face($font-poppins-400-12px, #898989);
          margin-top: 20px;
        }
      }

      &-footer-seperator {
        margin-bottom: 24px;
      }

      &-footer {
        @include font-face($font-poppins-400-12px, #898989);
        a {
          color: #407ec9;
          cursor: pointer;
        }
      }
    }
  }

  // Applied to all subforms:
  .MuiTextField-root {
    // @include font-face($font-poppins-400-14px, #898989);
    * {
      @include font-face($font-poppins-400-14px, #898989);
    }

    // workaround a bug with mui where the legend is bigger/smaller than font-size
    legend > span {
      margin-left: -14px;
    }
  }

  .MuiFormHelperText-root {
    @include font-face($font-poppins-400-12px, #ef4444);
  }

  .auth-form-confirm-btn {
    @include font-face($font-poppins-500-14px, #fff);
    height: 48px;
    border-radius: 8px;
    background: #407ec9;
    box-shadow: none;
    text-transform: none;
    &:hover {
      background: #407ec9;
      box-shadow: none;
    }
  }
  & .loading-image {
    max-height: 48px;
    width: 100%;
  }

  // Mobile styles
  @media (max-width: 768px) {
    top: 50px;
    max-width: calc(100% - 20px);
    max-height: calc(100% - 100px);
    height: 100%;
    grid-template-columns: 1fr;

    .auth-header {
      display: none;
    }

    .auth-form-container {
      .auth-form {
        margin: 10px 20px;
        max-width: none;
      }
    }

    .auth-modal {
      overflow-y: auto;
    }
  }
}
