@import "styles/utils";
@import "styles/font_variables";

.auth-form-congratulate {
  max-width: 370px;
  margin: 0 auto;
  text-align: center;

  img {
    width: 295px;
    height: 258px;
  }
  &-title {
    
    @include font-face($font-volkhov-700-32px, #202020);
  }
  &-subtitle {
    @include font-face($font-poppins-400-14px-1_7, #5a5a5a);
  }
}
