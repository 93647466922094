@import "styles/utils";
@import "styles/font_variables";

.auth-form-forgot-password {
  max-width: 370px;
  margin: 0 auto;
  &-title {
    @include font-face($font-poppins-600-24px, #202020);
  }
  &-subtitle {
    @include font-face($font-poppins-400-14px-1_7, #5a5a5a);
  }
  &-go-to-signup {
    @include font-face($font-poppins-300-14px, #202020);
    margin-top: 48px;
    width: 100%;
    text-align: center;
    a {
      @include font-face($font-poppins-600-14px, #407ec9);
      text-decoration: none;
      cursor: pointer;
    }
  }
}
