@import "styles/utils";
@import "styles/font_variables.scss";

.congratulate-lab-modal {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1254px;
  width: 100%;
  max-height: min(821px, calc(100% - 100px));
  height: 100%;

  background-color: #fff;
  box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
  border-radius: 20px;
  z-index: 100;

  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  white-space: pre-line;
  overflow-y: scroll;


  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
  }

  // Applied to all subforms:
  .MuiTextField-root {
    // @include font-face($font-poppins-400-14px, #898989);
    * {
      @include font-face($font-poppins-400-14px, #898989);
    }

    // workaround a bug with mui where the legend is bigger/smaller than font-size
    legend > span {
      margin-left: -14px;
    }
  }

  .MuiFormHelperText-root {
    @include font-face($font-poppins-400-12px, #ef4444);
  }

  .congratulate-lab-form-congratulate {
    max-width:900px;
    margin: 0 auto;
    height: 100%;
    text-align: center;

    
    .congratulate-lab-form-congratulate-background {
      width: 634px;
      height: auto;
    }
    img {
      width: 295px;
      height: 258px;
    }
    &-title {
      @include font-face($font-volkhov-700-30px, #111827);
    }
    &-subtitle {
      @include font-face($font-poppins-500-18px, #6B7280);
    }
    &-next {
      @include font-face($font-volkhov-700-32px, #202020);
      font-size: 20px;
    }
  }

  .congratulate-lab-form-confirm-btn {
    @include font-face($font-poppins-500-14px, #fff);
    height: 48px;
    width: 370px;
    margin-top: 13px;
    margin-bottom: 50px;
    border-radius: 16px;
    background: #407ec9;
    box-shadow: none;
    text-transform: none;
    &:hover {
      background: #407ec9;
      box-shadow: none;
    }
  }
  & .loading-image {
    max-height: 48px;
    width: 100%;
  }

  .back-home-btn {
    background-color: transparent;
    color: #9CA3AF;
    text-transform: none;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: none;
    position: absolute;
    bottom: 20px;

    &:hover {
      background-color: transparent;
      box-shadow: none;
    }
  }

  
}
