@import "styles/utils";
@import "styles/font_variables.scss";

.subscriptions-list-modal {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  & &-title {
    @include font-face($font-volkhov-700-30px, #111827);
    margin-bottom: 22px;
    text-align: center;
    text-transform: capitalize;
  }

  & &-forgot-password {
    margin-bottom: 37px;
    a {
      @include font-face($font-inter-400-14px, #407ec9);
      text-decoration: none;
    }
  }

  & &-btn-signin {
    @include font-face($font-poppins-500-14px, #fff);
    height: 48px;
    border-radius: 8px;
    background: #407ec9;
    box-shadow: none;
    text-transform: none;
    &:hover {
      background: #407ec9;
      box-shadow: none;
    }
  }

  & &-btn-signin-error {
    margin-left: 33px;
  }
}
