@import "styles/utils";
@import "styles/font_variables.scss";

.navbar-assignment-placeholder {
  height: 90px;
}

.navbar-assignment {
  position: fixed;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 20px 20px;
  background: #fff;
  box-shadow: 0px 4px 25px 0px rgba(105, 105, 108, 0.16);
  height: 98px;
  width: 100%;
  max-width: 1440px;
  z-index: 100;
  transition:
    border-radius 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;

  @media (max-width: 768px) {
    align-items: center;
  }

  &.mobile-open {
    border-radius: 0px;
    box-shadow: none;
  }

  .hamburger {
    display: none;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
    @media (max-width: 768px) {
      display: flex;
      margin-left: 20px;
    }

    .bar {
      width: 18px;
      height: 2px;
      background: #000;
      border-radius: 2px;
      transition: all 0.3s;

      &::after,
      &::before {
        content: "";
        width: 18px;
        height: 2px;
        background: #000;
        border-radius: 2px;
        transition: all 0.3s;
        position: absolute;
      }

      &::before {
        transform: translateY(-5px);
      }

      &::after {
        transform: translateY(5px);
      }

      &.mobile-open {
        background: #fff;
        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  .logo {
    font: $font-volkhov-400-26px;
    flex: 0 1 auto;
    text-align: center;
    img {
      max-width: 187px;
      width: 100%;
    }
    @media (min-width: 769px) {
      margin: auto 0px auto 55px;
    }
    @media (max-width: 768px) {
      flex: 1;
      text-align: center;
      margin: 0 20px;
      max-width: 62px;

      img {
        margin-top: 5px;
      }
    }
  }

  .search {
    @include h-center-content;
    margin: auto 0 auto 0;
    width: 401px;
    height: 48px;
    border-radius: 20px;
    box-shadow: 0px 2px 29px 0px rgba(144, 144, 144, 0.1);

    @media (max-width: 768px) {
      box-shadow: none;
      .MuiInputBase-input {
        opacity: 0;
        box-shadow: none;
      }
      img {
        width: 15px;
      }
    }
  }

  .menus {
    display: flex;
    flex-wrap: nowrap;
    gap: 185px;
    margin-left: auto;

    @media (max-width: 768px) {
      position: absolute;
      top: 97px;
      left: 0;
      right: 0;
      background: #fff;
      flex-direction: column;
      gap: 0;
      height: 0;
      overflow: hidden;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      height: fit-content;
      border-radius: 0 0 20px 20px;
      padding: 10px 0 25px;
      pointer-events: none;

      &.mobile-open {
        opacity: 1;
        pointer-events: all;
        box-shadow: 0px 20px 29px 0px rgba(100, 100, 111, 0.2);
      }
    }

    .navigation {
      display: flex;
      flex-wrap: nowrap;
      max-width: fit-content;
      gap: 47px;
      margin: auto 0 auto 0px;
      height: fit-content;

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
        max-width: none;
      }

      a {
        @include v-center-content;
        justify-content: center;
        cursor: pointer;
        font: $font-poppins-500-16px-1_34;
        color: #111827;
        text-align: center;
        letter-spacing: 0px;
        flex: 0 0 auto;
        text-decoration: none;

        &.active,
        &:hover {
          color: #407ec9;
        }
      }
    }
  }
  .actions {
    display: flex;
    margin-left: auto;

    @media (max-width: 768px) {
      margin-left: auto;
      margin-right: 20px;
    }

    .toolbar {
      @include center-content;
      margin: auto 55px auto 0;
      gap: 13px;

      @media (max-width: 768px) {
        margin: auto;
      }

      .bell_icon {
        margin-left: 6px;
        width: 24px;
        height: 24px;

        @media (max-width: 768px) {
          width: 15px;
          height: 15px;
        }
      }

      .profile_icon {
        @include center-content;
        margin-left: 6px;
        width: 44px;
        height: 44px;

        @media (max-width: 768px) {
          .MuiAvatar-root {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    .login {
      @include font-face($font-poppins-400-16px-1_38, #fff);
      @include flex-column;
      background-color: rgb(64, 126, 201);
      margin: auto 58px auto 0px;
      text-decoration: none;
      cursor: pointer;
      width: 102px;
      height: 41px;
      border-radius: 10px;
      justify-content: center;
      text-align: center;

      @media (max-width: 768px) {
        margin: 0;
        padding: 0 20px;
        height: 36px;
        width: fit-content;
        font-size: 12px;
      }
    }
  }
}
