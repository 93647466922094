@import "styles/utils";
@import "styles/font_variables.scss";

.login-form {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  & &-title {
    @include font-face($font-volkhov-700-30px, #111827);
    margin-bottom: 22px;
    text-align: center;
    text-transform: capitalize;
  }

  & &-subtitle {
    @include font-face($font-poppins-300-14px, #202020);
    margin-bottom: 48px;
    margin-left: 28px;
    a {
      @include font-face($font-poppins-600-14px, #407ec9);
      text-decoration: none;
      cursor: pointer;
    }
  }

  & &-email,
  & &-password,
  & &-forgot-password,
  & &-btn-signin {
    margin-left: 24px;
    margin-right: 24px;
  }

  & &-email,
  & &-password {
    .MuiInputBase-root {
      width: 100%;
    }
  }

  & &-password {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  & &-forgot-password {
    margin-bottom: 37px;
    a {
      @include font-face($font-inter-400-14px, #407ec9);
      text-decoration: none;
      cursor: pointer;
    }
  }

  & &-btn-signin {
    @include font-face($font-poppins-500-14px, #fff);
    height: 48px;
    border-radius: 8px;
    background: #407ec9;
    box-shadow: none;
    text-transform: none;
    &:hover {
      background: #407ec9;
      box-shadow: none;
    }
  }

  & &-btn-signin-error {
    margin-left: 33px;
  }
}
