@import "styles/utils";
@import "styles/font_variables";

.footer-socials {
  display: flex;
  flex-wrap: nowrap;
  margin: 16px 0 108px 40px;
  gap: 25px;
  max-width: 190px;

  @media (max-width: 768px) {
    margin: 16px 0 28px 0;
  }

  .item {
    @include flex-column;
    background-color: #fff;
    border-radius: 100%;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.098));
    cursor: pointer;
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
    
    img {
      max-width: 21px;

      &.linkedin {
        height: 16px;
        width: 16px;
        min-width: 16px;
      }
      &.meta {
        height: 13px;
        width: 7px;
      }
      &.twitter {
        height: 13px;
        width: 16px;
        min-width: 16px;
      }
    }
  }
}