@import "styles/utils";
@import "styles/font_variables";

.cookie-banner {
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
  }

  .cookie-customization-toggles {
    .MuiSwitch-track {
      width: 40px;
      height: 18px;
      margin-top: -3px;

      background: linear-gradient(
        180deg,
        rgba(184, 184, 184, 1) 0%,
        rgba(220, 220, 220, 1) 100%
      );
      box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.1);
    }
    .MuiSwitch-thumb {
      background: linear-gradient(180deg, #ffffff 0%, #f7f7f7 100%);
      box-shadow:
        1px 1px 2px rgba(0, 0, 0, 0.1),
        0px 2px 2px rgba(0, 0, 0, 0.1),
        1px 2px 4px rgba(0, 0, 0, 0.1);
      height: 18px;
      width: 18px;
    }

    .Mui-checked {
      + .MuiSwitch-track {
        background: rgba(64, 126, 201, 1);
        opacity: 1;
      }
      .MuiSwitch-thumb {
        background: linear-gradient(180deg, #ffffff 0%, #f7f7f7 100%);
        box-shadow:
          1px 1px 2px rgba(0, 0, 0, 0.1),
          0px 2px 2px rgba(0, 0, 0, 0.1),
          1px 2px 4px rgba(0, 0, 0, 0.1);
      }
    }
    .Mui-disabled {
      + .MuiSwitch-track {
        background: rgba(184, 184, 184, 1);
      }
    }
  }
}
